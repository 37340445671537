const gameCategories = [
    
    {
        sport_id: 79,
        sport_name: "Soccer",
    },
    {
        sport_id: 85,
        sport_name: "Basketball",
    },
    {
        sport_id: 107,
        sport_name: "Bseball",
    },
    {
        sport_id: 82,
        sport_name: "Ice Hockey",
    },
    {
        sport_id: 80,
        sport_name: "Tennis",
    },
    {
        sport_id: 83,
        sport_name: "Handball",
    },
    {
        sport_id: 178,
        sport_name: "Floorball",
    },
    {
        sport_id: 172,
        sport_name: "Boxing",
    },
    {
        sport_id: 97,
        sport_name: "Rugby",
    },
    {
        sport_id: 109,
        sport_name: "Aussie Rules",
    },
    {
        sport_id: 183,
        sport_name: "Bandy",
    },
    {
        sport_id: 162,
        sport_name: "American Football",
    },
    {
        sport_id: 189,
        sport_name: "Cycling",
    },
    {
        sport_id: 102,
        sport_name: "Snooker",
    },
    {
        sport_id: 104,
        sport_name: "Table Tennis",
    },
    {
        sport_id: 98,
        sport_name: "Cricket",
    },
    {
        sport_id: 99,
        sport_name: "Darts",
    },
    {
        sport_id: 84,
        sport_name: "Volleyball",
    },
    {
        sport_id: 168,
        sport_name: "Field Hockey",
    },
    {
        sport_id: 176,
        sport_name: "Water Polo",
    },
    {
        sport_id: 278,
        sport_name: "Curling",
    },
    {
        sport_id: 96,
        sport_name: "Futsal",
    },
    {
        sport_id: 86,
        sport_name: "Badminton",
    },
    {
        sport_id: 100,
        sport_name: "Bowls",
    },
    {
        sport_id: 105,
        sport_name: "Beach Volley",
    },
    {
        sport_id: 108,
        sport_name: "Squash",
    },
    {
        sport_id: 188,
        sport_name: "Biathlon",
    },
    {
        sport_id: 177,
        sport_name: "Beach Soccer",
    },
    {
        sport_id: 174,
        sport_name: "Pesapallo",
    },
    {
        sport_id: 184,
        sport_name: "Rally",
    },
    {
        sport_id: 168,
        sport_name: "Counter-Strike",
    },
    {
        sport_id: 286,
        sport_name: "ESport Counter-Strike",
    },
    {
        sport_id: 165,
        sport_name: "League of Legends",
    },
    {
        sport_id: 106,
        sport_name: "Dota 2",
    },
    {
        sport_id: 279,
        sport_name: "StarCraft",
    },
    {
        sport_id: 173,
        sport_name: "MMA",
    },
    {
        sport_id: 175,
        sport_name: "Speedway",
    },
    {
        sport_id: 181,
        sport_name: "Gaelic Football",
    },
    {
        sport_id: 179,
        sport_name: "Gaelic Hurling",
        priority: 1
    },
    {
        sport_id: 284,
        sport_name: "eSoccer",
    },
    {
        sport_id: 281,
        sport_name: "Kabaddi",
        priority: 1
    },
    {
        sport_id: 280,
        sport_name: "Basketball 3x3",
    },
    {
        sport_id: 194,
        sport_name: "Stock Car Racing",
    }        
]

const topSoccer = [
    [
        
        {
            "competition_id": 16982,
            "competition_name": "UEFA Champions League",
            "flag": "/img/flags-1-1/default_flag.svg",
            "category_id": 8116,
            "category": "International Clubs",
            "country_code": "null",
            "competition_prority": 146000,
            "sport_id": 79,
        },
        {
            "competition_id": 16967,
            "competition_name": "UEFA Europa League",
            "flag": "/img/flags-1-1/default_flag.svg",
            "category_id": 8116,
            "category": "International Clubs",
            "country_code": "null",
            "competition_prority": 145000,
            "sport_id": 79,
        },
        {
            "competition_id": 16805,
            "competition_name": "Premier League",
            "flag": "/img/flags-1-1/en.svg",
            "category_id": 8076,
            "category": "England",
            "country_code": "ENG",
            "competition_prority": 144000,
            "sport_id": 79,
        },
        {
            "competition_id": 16751,
            "competition_name": "LaLiga",
            "flag": "/img/flags-1-1/en.svg",
            "category_id": 8076,
            "category": "Spain",
            "country_code": "ENG",
            "competition_prority": 144000,
            "sport_id": 79,
        },
        {
            "competition_id": 16752,
            "competition_name": "LaLiga 2",
            "flag": "/img/flags-1-1/en.svg",
            "category_id": 8076,
            "category": "Spain",
            "country_code": "ENG",
            "competition_prority": 144000,
            "sport_id": 79,
        },
        {
            "competition_id": 16735,
            "competition_name": "Ligue 1",
            "flag": "/img/flags-1-1/en.svg",
            "category_id": 8076,
            "category": "Spain",
            "country_code": "ENG",
            "competition_prority": 144000,
            "sport_id": 79,
        },
        {
            "competition_id": 16745,
            "competition_name": "Serie A",
            "flag": "/img/flags-1-1/it.svg",
            "category_id": 8052,
            "category": "Italy",
            "country_code": "ITA",
            "competition_prority": 130000,
            "sport_id": 79,
        },
        {
            "competition_id": 16785,
            "competition_name": "Premier League",
            "flag": "/img/flags-1-1/tz.svg",
            "category_id": 8269,
            "category": "Tanzania",
            "country_code": "TZA",
            "competition_prority": 150000,
            "sport_id": 79,
        },
    ]
]

export default gameCategories;